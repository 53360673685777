import moment, { Moment } from "moment";
import { ChatStatus } from "request/apis/portageurTypes";
import { MessageSender } from "components/MessagesRenderer/types";

export const format = "YYYY-MM-DD HH:mm:ss";
export const simpleFormat = "YYYY-MM-DD";
export const startFormat = "YYYY-MM-DD 00:00:00";
export const endFormat = "YYYY-MM-DD 23:59:59";
export const startDate = moment(
  moment().subtract(30, "days").format(startFormat),
);
export const endDate = moment(moment().format(endFormat));

export const RANGE_FORMAT: { [key: string]: [Moment, Moment] } = {
  Today: [moment(), moment()],
  "Last 7 days": [moment().subtract(6, "days"), moment()],
  "Last 30 days": [moment().subtract(29, "days"), moment()],
  "Last 365 days": [moment().subtract(364, "days"), moment()],
};

const getStrongStr = (str: string) =>
  `<strong><span style="color:#3A404D">${str}</span></strong>`;

export const TABLE_INFO = {
  Basic: {
    intro: "For Those Getting Started",
    feat: [
      `${getStrongStr("40 librarian requests")} per month`,
      `${getStrongStr(
        "SEC filings",
      )} to assess companies' financial health, governance, and risk profile`,
      `${getStrongStr(
        "Investor presentations",
      )} to understand strategic vision and growth plans`,
      `${getStrongStr(
        "Corporate policies",
      )} to evaluate commitments to sustainability and ethical practices`,
      `${getStrongStr(
        "Auditable insights",
      )} with direct reference links to source documents`,
      `${getStrongStr(
        "Actionable competitive advantage summary,",
      )} crafted by ex-institutional investors`,
      `${getStrongStr("Mobile responsive")} for on-the-go access`,
    ],
  },
  Premium: {
    intro: "For Those Who Want to Go Deep",
    feat: [
      `${getStrongStr("Everything in Basic,")} and:`,
      `${getStrongStr("400 librarian requests")} per month`,
      `${getStrongStr("Compare companies")} across multiple documents`,
      `${getStrongStr("5x more documents")} analyzed for deeper insights`,
    ],
  },
  Enterprise: {
    intro: "For Fast-Moving Teams",
    feat: [
      `${getStrongStr("Everything in Premium,")} and:`,
      `${getStrongStr(
        "Automate Customized Evaluation Templates",
      )} to analyze thousands of companies your way and uncover hidden insights`,
      `${getStrongStr(
        "API Access",
      )} to seamlessly integrate with existing workflows and access insights on demand`,
    ],
  },
};

export const WELCOME_TIP = [
  {
    icon: "icons",
    title: "Ask 5 Questions Per Day",
    content:
      "Get instant insights from SEC filings, investor presentations and corporate policies.",
  },
  {
    icon: "highlighter",
    title: "Audit with Document Highlights",
    content:
      "Find data with confidence, with direct reference links to source documents.",
  },
  {
    icon: "review",
    title: "Find Company Competitive Advantages",
    content:
      "Get actionable insights from competitive advantage summary, crafted by ex-institutional investors.",
  },
];

export const FRONTEND_MESG = {
  CompanyCode: {
    message:
      "I don't understand your request. Please type '@' followed by company name.",
    status: "error" as ChatStatus,
    sender: "bot" as MessageSender,
  },
  Placeholder: {
    message: "Please fill in or remove placeholders.",
    status: "error" as ChatStatus,
    sender: "bot" as MessageSender,
  },
  OneCompany: {
    message: "Enter one company and try again using '@companyname'.",
    status: "error" as ChatStatus,
    sender: "bot" as MessageSender,
  },
  AmericanCompany: {
    message:
      "This analysis is for U.S. listed companies. Try again using '@companyname'.",
    status: "error" as ChatStatus,
    sender: "bot" as MessageSender,
  },
  RecipeQuestion: {
    message:
      "Which company are you analyzing? Enter '@companyname'. This analysis is for U.S. public companies.",
    status: "question" as ChatStatus,
    sender: "bot" as MessageSender,
  },
  StartNew: {
    message: "To ask more questions, start a new chat.",
    status: "error" as ChatStatus,
    sender: "bot" as MessageSender,
  },
};

export const SOURCE = [
  // { name: '10-K', description: 'US public companies only', code: 1001 },
  {
    name: "10-K/Annual Reports",
    description: "US public companies",
    code: 2002,
  },
  {
    name: "10-Q",
    description: "US public companies",
    code: 2004,
  },
  {
    name: "Investor Presentations",
    description: "Growth strategies & future plans",
    code: 2003,
  },
  {
    name: "Sustainability Reports",
    description: "Impact on environmental, social & governance issues",
    code: 2001,
  },
  {
    name: "Web Search",
    description:
      "Internet search (default selection for supplementary information)",
    code: 0,
    default: true,
  },
];
